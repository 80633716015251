import { Fragment, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import { CheckboxIcon } from '@demandscience/ui';
import MenuItem from './MenuItem';
import { Combobox } from '@headlessui/react';

export type Option<T = any> = {
  avatar?: string;
  description?: string;
  disabled?: boolean;
  icon?: ReactNode;
  id: string;
  label: string;
  postLabel?: React.ReactNode;
  value: T;
};
interface CheckboxMenuItemProps {
  avatarClassName?: string;
  className?: string;
  forceSelected?: boolean;
  onToggle?: (option: Option) => void;
  option: Option; // New optional prop
}

const CheckboxMenuItem = ({
  className,
  option,
  avatarClassName,
  onToggle,
  forceSelected,
}: CheckboxMenuItemProps) => {
  const handleToggle =
    (isCurrentlySelected: boolean): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      if (isCurrentlySelected) {
        e.preventDefault();

        if (onToggle) onToggle(option);
      } else {
        option.value.exclude = true;
      }
    };

  return (
    <Combobox.Option value={option.value} as={Fragment} disabled={option.disabled}>
      {({ active, selected }) => {
        const isSelected = forceSelected !== undefined ? forceSelected : selected;
        return (
          <MenuItem
            className={overrideTailwindClasses(
              classNames(className, 'group', {
                'hover:bg-gray-50 cursor-pointer': !option.disabled,
                'bg-gray-50': !isSelected && active,
                'bg-gray-100 hover:bg-gray-200': isSelected && !active,
                'bg-gray-200 hover:bg-gray-200': isSelected && active,
              }),
            )}
            titleClassName={classNames('flex flex-row gap-2', { 'text-gray-400': option.disabled })}
            title={
              <>
                <div className="ellipsis">{option.label}</div>
                {option.postLabel}
              </>
            }
            subtitleClassName="ellipsis"
            subtitle={option.description}
            leadingContent={
              <CheckboxIcon
                size={18}
                theme={option.value.exclude ? 'secondary' : 'primary'}
                checkType={option.value.exclude ? 'minus' : 'check'}
                checked={isSelected}
                disabled={option.disabled}
              />
            }
            trailingContent={
              <div className="flex flex-row gap-4">
                {onToggle && (
                  <button
                    className={classNames('text-xs text-gray-500 hidden group-hover:block', {
                      'hover:text-rose-500': !option.value.exclude,
                      'hover:text-emerald-500': option.value.exclude,
                    })}
                    onClick={handleToggle(isSelected)}
                  ></button>
                )}
                {option.icon ||
                  (option.avatar && (
                    <img
                      alt=""
                      src={option.avatar}
                      className={overrideTailwindClasses(
                        classNames('w-6 h-6 rounded-full object-cover', avatarClassName),
                      )}
                      onError={(e) => {
                        e.currentTarget.style.display = 'none';
                      }}
                    />
                  ))}
              </div>
            }
          />
        );
      }}
    </Combobox.Option>
  );
};

export default CheckboxMenuItem;
