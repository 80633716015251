import React, { Fragment, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import { Combobox } from '@headlessui/react';
import { IconButton, CheckboxIcon, Transition } from '@demandscience/ui';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';
import ChevronUpIcon from '@demandscience/ui/icons/chevron-up';
import MenuItem from 'components/Filter/Dropdown/MenuItem';
import CheckboxMenuItem from 'components/Filter/Dropdown/CheckboxMenuItem';
import { map } from 'lodash';

export interface IndustryNode {
  category?: string;
  exclude?: boolean;
  removed?: boolean;
  sector?: string;
  sectors?: IndustryNode[];
  sub_sector?: string;
  sub_sectors?: IndustryNode[];
  total_companies?: number;
}

export interface Option<T = any> {
  id: string;
  label: string;
  options?: Option<T>[];
  value: T;
}

export interface IndustryMenuItemItemProps {
  depth: number; // 0: category, 1: sector, 2: subsector
  expanded?: boolean;
  onChange: (
    updatedNode: IndustryNode | null | { removed: true; sector?: string; sub_sector?: string },
  ) => void;
  onToggle?: (option: Option<IndustryNode>) => void;
  option: Option<IndustryNode>;
  options: Option<IndustryNode>[];
  value: IndustryNode | null;
}

function getIndentClass(depth: number): string {
  switch (depth) {
    case 0:
      return 'pl-1';
    case 1:
      return 'pl-4';
    case 2:
      return 'pl-16';
    default:
      return `pl-${3 * depth}`;
  }
}

const IndustryMenuItemItem: React.FC<IndustryMenuItemItemProps> = ({
  depth,
  option,
  options,
  value,
  onChange,
  onToggle,
  expanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const isLeaf = depth === 2;
  // category (depth 0) → "sectors", sector (depth 1) → "sub_sectors"
  const childrenKey = depth === 0 ? 'sectors' : depth === 1 ? 'sub_sectors' : null;
  const currentChildren: IndustryNode[] = useMemo(() => {
    if (childrenKey && value) {
      return value[childrenKey] || [];
    }
    return [];
  }, [childrenKey, value]);

  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  // Handler for leaf nodes (subsectors)
  const handleLeafToggle = useCallback(
    (child: Option<IndustryNode>) => {
      const alreadySelected = currentChildren.some((c) => c.sub_sector === child.value.sub_sector);
      if (alreadySelected) {
        onChange({
          removed: true,
          sector: option.value.sector, // parent sector from the current node
          sub_sector: child.value.sub_sector,
        });
      } else {
        // Add subsector:
        const newChildren = [...currentChildren, child.value];
        const currentNode = value || { ...option.value };
        const newNode = { ...currentNode, [childrenKey!]: newChildren };
        onChange(newNode);
      }
    },
    [currentChildren, onChange, value, option.value, childrenKey],
  );

  const handleChildChange = useCallback(
    (child: Option<IndustryNode>, updatedChild: IndustryNode | null) => {
      let newChildren = [...currentChildren];
      const idx = newChildren.findIndex((c) => c.sector === child.value.sector);
      if (updatedChild === null) {
        if (idx !== -1) {
          onChange({ removed: true, sector: child.value.sector });
          newChildren.splice(idx, 1);
        }
      } else {
        if (idx !== -1) {
          newChildren[idx] = updatedChild;
        } else {
          newChildren.push(updatedChild);
        }
        const currentNode = value || { ...option.value };
        const newNode = { ...currentNode, [childrenKey!]: newChildren };
        onChange(newNode);
      }
    },
    [currentChildren, onChange, value, option.value, childrenKey],
  );

  const handleParentToggle = () => {
    if (value) {
      if (depth === 0) {
        onChange(null);
      } else if (depth === 1) {
        onChange({ removed: true, sector: option.value.sector });
      }
    } else {
      if (depth === 1) {
        const newSectorNode = {
          ...option.value,
          sub_sectors: (option.options || []).map((child: any) => ({
            sub_sector: child.value.sub_sector,
            exclude: false,
            total_companies: child.value.total_companies,
          })),
        };
        onChange(newSectorNode);
      } else {
        onChange({
          ...option.value,
          sectors: option?.options?.map((sector: Option<IndustryNode>) => ({
            sector: sector.value.sector,
            exclude: false,
            total_companies: sector.value.total_companies,
            sub_sectors: (sector.options || []).map((sub: Option<IndustryNode>) => ({
              sub_sector: sub.value.sub_sector,
              exclude: false,
              total_companies: sub.value.total_companies,
            })),
          })),
        });
      }
    }
  };

  const renderChild = (child: Option<IndustryNode>) => {
    if (depth === 0) {
      const childValue = currentChildren.find((c) => c.sector === child.value.sector) || null;
      return (
        <IndustryMenuItemItem
          key={child.id}
          depth={1}
          option={child}
          options={child.options || []}
          value={childValue}
          onChange={(updatedChild) => {
            return handleChildChange(child, updatedChild);
          }}
          onToggle={onToggle}
        />
      );
    } else if (depth === 1) {
      const isLeafChecked = currentChildren.some((c) => c.sub_sector === child.value.sub_sector);
      return (
        <CheckboxMenuItem
          key={child.id}
          className={getIndentClass(2)}
          option={child}
          forceSelected={isLeafChecked}
          onToggle={() => {
            return handleLeafToggle(child);
          }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Combobox.Option value={option.value} as={Fragment}>
        {({ active }) => {
          let computedChecked = false;
          let isPartial = false;
          if (depth === 0 && value) {
            const totalSectorCount = options.length;
            const selectedSectorCount = value.sectors?.length || 0;
            if (!value.sectors || selectedSectorCount === 0) {
              computedChecked = true;
              isPartial = false;
            } else if (selectedSectorCount > 0) {
              computedChecked = true;
              if (selectedSectorCount < totalSectorCount) {
                isPartial = true;
              } else {
                let allSectorsFullySelected = true;
                for (const selSector of value.sectors) {
                  if (selSector.sub_sectors) {
                    const sectorOption = options.find(
                      (opt) => opt.value.sector === selSector.sector,
                    );
                    const totalSubs = sectorOption?.options?.length || 0;
                    const selectedSubs = selSector.sub_sectors.length;
                    if (selectedSubs > 0 && selectedSubs < totalSubs) {
                      allSectorsFullySelected = false;
                      break;
                    }
                  }
                }
                isPartial = !allSectorsFullySelected;
              }
            }
          } else if (depth === 1 && value) {
            const totalSubCount = options.length;
            const selectedSubCount = value.sub_sectors?.length || 0;
            if (!value.sub_sectors || selectedSubCount === 0) {
              computedChecked = true;
              isPartial = false;
            } else if (selectedSubCount > 0) {
              computedChecked = true;
              isPartial = selectedSubCount < totalSubCount;
            }
          } else {
            computedChecked = !!value;
          }
          const visualSelected = isLeaf ? !!value : computedChecked || isPartial;

          return (
            <MenuItem
              className={overrideTailwindClasses(
                classNames(getIndentClass(depth), 'group cursor-pointer hover:bg-gray-50', {
                  'bg-gray-50': !visualSelected && active,
                  'bg-gray-100 hover:bg-gray-200': visualSelected && !active,
                  'bg-gray-200 hover:bg-gray-200': visualSelected && active,
                }),
              )}
              leadingContent={
                <div className="flex items-center gap-2">
                  {options && options.length > 0 && depth < 2 && (
                    <IconButton
                      size="xs"
                      theme="default"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleExpand();
                      }}
                      Icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                    />
                  )}
                  {depth < 2 ? (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleParentToggle();
                      }}
                    >
                      <CheckboxIcon
                        size={18}
                        theme={visualSelected ? 'primary' : 'gray'}
                        checkType={isPartial ? 'partial' : option.value.exclude ? 'minus' : 'check'}
                        checked={visualSelected}
                      />
                    </span>
                  ) : (
                    <CheckboxIcon
                      size={18}
                      theme={visualSelected ? 'primary' : 'gray'}
                      checkType={option.value.exclude ? 'minus' : 'check'}
                      checked={visualSelected}
                    />
                  )}
                </div>
              }
              title={option.label}
              onClick={(e) => {
                e.preventDefault();
                if (depth < 2) {
                  toggleExpand();
                }
              }}
            />
          );
        }}
      </Combobox.Option>
      {depth < 2 && isExpanded && options && options.length > 0 && (
        <Transition.Collapse show={isExpanded}>
          <div>{map(options, renderChild)}</div>
        </Transition.Collapse>
      )}
    </>
  );
};

export default IndustryMenuItemItem;
