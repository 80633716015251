import { CompanyFilterName, badgeDisplayCap } from 'types/filters';
import NestedSelectFilter from './NestedSelectFilter';
import { FilterProps } from './types';
import useIndustryOptions from './Options/useIndustryOptions';
import { IndustryWithSubindustries } from 'types';

const CompanyIndustryFilter = (props: FilterProps) => {
  const query = useIndustryOptions();
  const industries = query.data || [];

  const calculateTotalCompanies = (industries: { total_companies?: number }[]) =>
    industries.reduce((total, industry) => total + (industry.total_companies || 0), 0);

  const totalCompanies = calculateTotalCompanies(industries);
  return (
    <NestedSelectFilter
      {...props}
      totalCompanies={totalCompanies}
      name={CompanyFilterName.CompanyIndustry}
      placeholder="Enter industry"
      showIncludeExclude
      industries={(industries || []) as IndustryWithSubindustries[]}
      badgeDisplayCap={badgeDisplayCap}
      container={props.container} // ensure container is provided
    />
  );
};

export default CompanyIndustryFilter;
