import { ListOrigin, ListRecord } from 'types';
import useModal from 'hooks/useModal';
import useAuth from 'components/Auth/useAuth';
import useModelPermissions from 'hooks/useModelPermissions';
import ListRecordEditActionModal from './ListRecordEditActionModal';
import ListRecordDeleteActionModal from './ListRecordDeleteActionModal';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';
import { DropdownMenu, DropdownMenuProps, IconButton, Tooltip } from '@demandscience/ui';
import classNames from 'classnames';
import useOrganization from 'components/Organization/useOrganization';
import axios from 'axios';
import fileDownload from 'js-file-download';
import api from 'api';

interface ListRecordActionsProps extends DropdownMenuProps {
  list: ListRecord;
}

const ListRecordActions = ({ list, ...props }: ListRecordActionsProps) => {
  const { open: editModalOpen, openModal: openEditModal, closeModal: closeEditModal } = useModal();
  const {
    open: deleteConfirmationModalOpen,
    openModal: openDeleteConfirmationModal,
    closeModal: closeDeleteConfirmationModal,
  } = useModal();
  const { data: organization } = useOrganization();
  const { user } = useAuth();
  const { owner, visibility } = list;
  const { isEditable } = useModelPermissions(user, owner, visibility);

  const isDownloadOptionAvailable = list.records_origin === ListOrigin.Upload;
  const isRegularMenuAvailable =
    isEditable &&
    (list.records_origin === ListOrigin.Search || list.records_origin === ListOrigin.Upload);
  const shouldShowDropdown = isDownloadOptionAvailable || isRegularMenuAvailable;

  const handleDownloadOriginalFile = async () => {
    try {
      const fileName = list.name ? `${list.name}.csv` : 'List original File.csv';
      const { data: signedUrl } = await api.get(`/list/${list.id}/download`);
      const { data: fileBlob } = await axios.get(signedUrl, { responseType: 'blob' });
      fileDownload(fileBlob, fileName);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error downloading original file:', error);
    }
  };

  const options = [];

  if (isRegularMenuAvailable) {
    options.push({ label: 'Edit list settings', action: openEditModal });
    options.push({ label: 'Delete list', action: openDeleteConfirmationModal });
  }
  if (isDownloadOptionAvailable) {
    options.push({ label: 'Download original file', action: handleDownloadOriginalFile });
  }

  return (
    <>
      <Tooltip
        title="Data loading is in progress, 
      this option will be available shortly"
        position="left"
        interactive
        showArrow
        tooltipClassName="max-w-xs whitespace-normal break-words p-2"
        disabled={!organization?.list_write_disabled}
      >
        <DropdownMenu
          className={classNames({ invisible: !shouldShowDropdown })}
          options={options}
          buttonType="custom"
          customButton={
            <IconButton
              className="hover:bg-gray-100"
              size="sm"
              theme="default"
              Icon={MoreVerticalIcon}
            />
          }
          origin="bottom-right"
          {...props}
          disabled={organization?.list_write_disabled}
        />
      </Tooltip>
      <ListRecordEditActionModal open={editModalOpen} onClose={closeEditModal} {...list} />
      <ListRecordDeleteActionModal
        open={deleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        {...list}
      />
    </>
  );
};

export default ListRecordActions;
