import useConsentManager from 'components/ConsentManager/useConsentManager';
import WebWidget from './WebWidget';

const Zendesk = () => {
  const { functional } = useConsentManager();

  if (!functional) {
    return null;
  }

  return <WebWidget instanceKey="a82affaa-7541-4d56-ba40-f0f6d6696e8c" />;
};

export default Zendesk;
