import { SyntheticEvent, useState } from 'react';
import { Badge } from '@demandscience/ui';
import { isEmpty, map } from 'lodash';
import { Size } from '@demandscience/ui/src/types';

export interface BadgesProps {
  allOptions?: any | undefined;
  badgeDisplayCap?: number;
  badgeSize?: Size;
  className?: string;
  getBadgeProps: (value: any) => { exclude?: boolean; key: string; label: string };
  onDelete: (value: any) => void;
  value?: any;
}

// Aggregation function – flattens values based on full/partial selections
const aggregateBadgeValues = (values: any[], allOptions?: any[]): any[] => {
  if (!values) return [];
  const result: any[] = [];

  values.forEach((item) => {
    // CASE 1: Old structure with "sectors" array.
    if (item.sectors && Array.isArray(item.sectors) && item.sectors.length > 0) {
      item.sectors.forEach((sector: any) => {
        if (sector.sub_sectors && Array.isArray(sector.sub_sectors)) {
          let fullSubSectors: any[] = [];
          if (allOptions) {
            const fullCategory = allOptions.find(
              (opt: any) => opt.value.category === item.category,
            );
            const fullSector = fullCategory?.options?.find(
              (s: any) => s.value.sector === sector.sector,
            );
            fullSubSectors = fullSector?.options || [];
          }
          // Check if the sub_sectors length matches and verify each sub_sector exists in fullSubSectors
          const allSubSectorsMatch =
            !allOptions ||
            (sector.sub_sectors.length === fullSubSectors.length &&
              sector.sub_sectors.every((sub: any) =>
                fullSubSectors.some((fullSub: any) => fullSub.value.sub_sector === sub.sub_sector),
              ));

          if (allSubSectorsMatch) {
            result.push({
              type: 'sector',
              category: item.category,
              sector: sector.sector,
              exclude: sector.exclude,
              total_companies: sector.total_companies,
            });
          } else {
            // Partially selected: flatten to individual sub_sector badges.
            sector.sub_sectors.forEach((sub: any) => {
              result.push({
                type: 'sub_sector',
                category: item.category,
                sector: sector.sector,
                sub_sector: sub.sub_sector,
                exclude: sub.exclude,
                total_companies: sub.total_companies,
              });
            });
          }
        } else {
          // No sub_sectors array: treat as fully selected sector.
          result.push({
            type: 'sector',
            category: item.category,
            sector: sector.sector,
            exclude: sector.exclude,
            total_companies: sector.total_companies,
          });
        }
      });
    }
    // CASE 2: New structure – item has "sector" and "sub_sectors" directly.
    else if (item.sector && item.sub_sectors && Array.isArray(item.sub_sectors)) {
      let fullSubSectors: any[] = [];
      if (allOptions) {
        const fullCategory = allOptions.find((opt: any) => opt.value.category === item.category);
        const fullSector = fullCategory?.options?.find((s: any) => s.value.sector === item.sector);
        fullSubSectors = fullSector?.options || [];
      }
      const allSubSectorsMatch =
        !allOptions ||
        (item.sub_sectors.length === fullSubSectors.length &&
          item.sub_sectors.every((sub: any) =>
            fullSubSectors.some((fullSub: any) => fullSub.value.sub_sector === sub.sub_sector),
          ));

      if (allSubSectorsMatch) {
        result.push({
          type: 'sector',
          category: item.category,
          sector: item.sector,
          exclude: item.exclude,
          total_companies: item.total_companies,
        });
      } else {
        // Partially selected: flatten to sub_sector badges.
        item.sub_sectors.forEach((sub: any) => {
          result.push({
            type: 'sub_sector',
            category: item.category,
            sector: item.sector,
            sub_sector: sub.sub_sector,
            exclude: sub.exclude,
            total_companies: sub.total_companies,
          });
        });
      }
    }
    // CASE 3: Fallback – treat as a fully selected category.
    else {
      result.push({
        type: 'category',
        category: item.category,
        exclude: item.exclude,
      });
    }
  });
  return result;
};

const Badges = ({
  className,
  value,
  onDelete,
  getBadgeProps,
  badgeSize,
  badgeDisplayCap,
  allOptions = undefined,
}: BadgesProps) => {
  const [isShowAll, setIsShowAll] = useState(false);

  const handleDelete = (option: any) => (e: SyntheticEvent) => {
    e.stopPropagation();
    onDelete(option);
  };

  const handleToggleShowMore = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsShowAll(!isShowAll);
  };

  if (isEmpty(value)) {
    return null;
  }
  const processedValues = allOptions ? aggregateBadgeValues(value, allOptions) : value;
  return (
    <div className={className}>
      <div className="flex flex-row flex-wrap items-center gap-[6px]">
        {map(isShowAll ? processedValues : processedValues.slice(0, badgeDisplayCap), (v) => {
          const { key, label, exclude, ...rest } = getBadgeProps(v);

          return (
            <Badge
              key={key}
              size={badgeSize || 'sm'}
              onDelete={handleDelete(v)}
              color={exclude ? 'rose' : 'emerald'}
              {...rest}
            >
              {label}
            </Badge>
          );
        })}
      </div>

      {badgeDisplayCap && badgeDisplayCap < processedValues.length ? (
        <button className="text-xs text-blue-500" onClick={handleToggleShowMore}>
          {isShowAll ? 'Show less' : `Show more (${processedValues.length - badgeDisplayCap})`}
        </button>
      ) : null}
    </div>
  );
};

export default Badges;
