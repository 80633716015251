import { useQuery } from '@tanstack/react-query';
import { fetchFilterData } from 'api/data';
import map from 'lodash/map';
import { FilterDataFieldName } from 'types';

const fetchOptions = async () => {
  const industries = await fetchFilterData(FilterDataFieldName.Industries);

  const data = map(industries, (industry) => {
    const { sub_category, ...rest } = industry;
    return rest;
  });
  return data;
};

const useIndustryOptions = (options?: Record<string, any>) => {
  const query = useQuery(['filterOptions', FilterDataFieldName.Industries], fetchOptions, {
    refetchOnWindowFocus: false, // no need to refetch on window focus
    staleTime: Infinity,
    ...options,
  });

  return query;
};

export default useIndustryOptions;
