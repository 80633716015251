import { Button, Modal, ModalProps } from '@demandscience/ui';
import Paragraph from 'components/Typography/Paragraph';
import { isEqual, map, sortBy, without } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  allCompanyTableColumns,
  allContactTableColumns,
  CompanyTableColumn,
  ContactTableColumn,
  defaultCompanyTableColums,
  defaultContactTableColumns,
  KlarityFeature,
  ModelType,
  tableColumns,
} from 'types';
import SortableContainer from 'components/SortableList/SortableContainer';
import SortableItem from 'components/SortableList/SortableItem';
import { getRecordsLabel } from 'utils/string';
import useCustomization from '../useCustomization';
import ColumnBox from './ColumnBox';
import DragPreview from './DragPreview';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

type ColumnType = ContactTableColumn | CompanyTableColumn;

export interface CustomizeColumnsOverlayProps extends Omit<ModalProps, 'children'> {
  type: ModelType;
}

const CustomizeColumnsOverlay = ({ type, ...props }: CustomizeColumnsOverlayProps) => {
  const { onClose } = props;
  const { results, onChange, incrementResetWidthsKey } = useCustomization();
  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);
  const {
    [type === ModelType.Contact ? 'contactsTableColums' : 'companiesTableColums']: persistedColumns,
  } = results;
  const [columns, setColumns] = useState<ColumnType[]>([]);
  const columnsAvailable = useMemo(() => {
    let res: ColumnType[] = [];
    if (type === ModelType.Contact) {
      res = without(allContactTableColumns, ...(columns || []));

      if (isContactDisabled) {
        // Remove ContactDetails column
        res = without(
          allContactTableColumns,
          ...(columns || []),
          ...[ContactTableColumn.ContactDetails],
        );
      }
    } else if (type === ModelType.Company) {
      res = without(allCompanyTableColumns, ...(columns || []));

      if (isContactDisabled || isContactRenderDisabled) {
        // Remove ContactDetails column
        res = without(
          allCompanyTableColumns,
          ...(columns || []),
          ...[CompanyTableColumn.ContactDetails],
        );
      }
    }

    // available columns must be alphabetically sorted
    res = sortBy(res, (column) => tableColumns[column]);

    return res;
  }, [columns, isContactDisabled, isContactRenderDisabled, type]);

  const handleAddColumn = useCallback(
    (column: ColumnType) => () => {
      setColumns((state = []) => [...state, column]);
    },
    [],
  );

  const handleRemoveColumn = useCallback(
    (column: ColumnType) => () => {
      setColumns((state) => without(state, column));
    },
    [],
  );

  const handleReorderChange = useCallback((columns: ColumnType[]) => {
    setColumns(columns);
  }, []);

  const handleReset = useCallback(() => {
    if (type === ModelType.Contact) {
      let contactTableColums = defaultContactTableColumns;
      if (isContactDisabled) {
        // Remove ContactDetails column
        contactTableColums = without(
          defaultContactTableColumns,
          ...[ContactTableColumn.ContactDetails],
        );
      }

      setColumns(contactTableColums);
    } else if (type === ModelType.Company) {
      let companyTableColums = defaultCompanyTableColums;
      if (isContactDisabled || isContactRenderDisabled) {
        // Remove ContactDetails column
        companyTableColums = without(
          defaultCompanyTableColums,
          ...[CompanyTableColumn.ContactDetails],
        );
      }
      setColumns(companyTableColums);
    }

    incrementResetWidthsKey();
  }, [incrementResetWidthsKey, isContactDisabled, isContactRenderDisabled, type]);

  const handleSave = useCallback(() => {
    onClose();

    const key = type === ModelType.Contact ? 'contactsTableColums' : 'companiesTableColums';
    onChange('results', { ...results, [key]: columns });
  }, [columns, onChange, onClose, results, type]);

  useEffect(() => {
    let newPersistedColumns = persistedColumns;

    if (type === ModelType.Contact) {
      if (isContactDisabled) {
        // Remove ContactDetails column
        newPersistedColumns = without(persistedColumns, ...[ContactTableColumn.ContactDetails]);
      }
    } else if (type === ModelType.Company) {
      if (isContactDisabled || isContactRenderDisabled) {
        // Remove ContactDetails column
        newPersistedColumns = without(persistedColumns, ...[CompanyTableColumn.ContactDetails]);
      }
    }

    setColumns(newPersistedColumns);
  }, [isContactDisabled, isContactRenderDisabled, persistedColumns, type]);

  return (
    <Modal
      aria-labelledby="customize-columns-dialog-title"
      className="max-w-xl sm:max-w-xl overflow-visible"
      {...props}
    >
      <Modal.Title id="customize-columns-dialog-title" className="flex mb-4 whitespace-pre">
        Customize <span className="capitalize">{getRecordsLabel(type)}</span> columns
      </Modal.Title>
      <div className="flex flex-row gap-4 justify-between w-full">
        <div className="w-full max-w-[240px]">
          <Paragraph className="text-gray-500 mb-4">Drag to reorder</Paragraph>
          <SortableContainer<ColumnType>
            items={columns}
            onChange={handleReorderChange}
            renderDragPreview={(column) => <DragPreview column={column} />}
          >
            <div className="space-y-1 h-80 overflow-y-scroll">
              {map(columns, (column) => {
                const disabled =
                  column === ContactTableColumn.ContactName ||
                  column === CompanyTableColumn.CompanyName;

                return (
                  <SortableItem key={column} id={column} disabled={disabled}>
                    <ColumnBox
                      label={tableColumns[column]}
                      disabled={disabled}
                      checked
                      onChange={handleRemoveColumn(column)}
                    />
                  </SortableItem>
                );
              })}
            </div>
          </SortableContainer>
        </div>
        <div className="w-full max-w-[240px]">
          <Paragraph className="text-gray-500 mb-4">Select to add</Paragraph>
          <div className="space-y-1 h-80 overflow-y-scroll">
            {map(columnsAvailable, (column) => (
              <ColumnBox
                key={column}
                label={tableColumns[column]}
                onChange={handleAddColumn(column)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <Button borderless onClick={handleReset} type="button">
          Reset to default
        </Button>
        <div className="flex space-x-2">
          <Button borderless onClick={onClose} type="button">
            Cancel
          </Button>
          <Button
            theme="primary"
            onClick={handleSave}
            disabled={isEqual(persistedColumns, columns)}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomizeColumnsOverlay;
