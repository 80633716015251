import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  isEmpty,
  uniq,
  map,
  find,
  isEqual,
  reduce,
  pick,
  omit,
  size,
  every,
  some,
  compact,
  filter,
  findIndex,
  differenceBy,
  unionBy,
} from 'lodash';
import Dropdown from '../Filter/Dropdown';
import LinkMenuItem from '../Filter/Dropdown/LinkMenuItem';
import TextMenuItem from '../Filter/Dropdown/TextMenuItem';
import FilterInput from './FilterInput';
import Inline from 'components/Filter/Inline';
import useFilter from './useFilter';
import { CompanyFilterName, FilterName, IndustryByCategory } from 'types';
import { FilterProps } from './types';
import IndustryMenuItemItem, { IndustryNode } from './IndustryMenuItem';

export type CompanyIndustryFilterValue = {
  category: string;
  exclude?: boolean;
  sectors?: {
    exclude?: boolean;
    sector: string;
    sub_sectors?: {
      exclude?: boolean;
      sub_sector: string;
      total_companies?: number;
    }[];
    total_companies?: number;
  }[];
  sub_category?: {
    exclude?: boolean;
    sub_category: string;
    total_companies?: number;
  }[];
  total_companies?: number;
}[];

interface Option<T = any> {
  id: string;
  label: string;
  options?: Option<T>[];
  value: T;
}

function filterOptions(options: Option<any>[], query: string): Option<any>[] {
  if (!query.trim()) return options;
  const lowerQuery = query.toLowerCase();

  const matches = (label: string) => {
    const lowerLabel = label.toLowerCase();
    if (lowerQuery.length > lowerLabel.length) {
      return lowerLabel === lowerQuery;
    }
    return lowerLabel.includes(lowerQuery);
  };

  return options
    .map((category) => {
      const filteredSectors = (category.options || [])
        .map((sector) => {
          const filteredSubs = (sector.options || []).filter((sub) => matches(sub.label));
          if (matches(sector.label) || filteredSubs.length > 0) {
            return { ...sector, options: filteredSubs };
          }
          return null;
        })
        .filter((sec) => sec !== null);

      if (matches(category.label) || filteredSectors.length > 0) {
        return { ...category, options: filteredSectors };
      }
      return null;
    })
    .filter((cat) => cat !== null) as Option<any>[];
}

type RawIndustryFilterValue = Partial<IndustryNode>[];

export const transformRawValue = (
  raw: RawIndustryFilterValue,
  allOptions: Option<IndustryNode>[],
): CompanyIndustryFilterValue => {
  const outputMap = new Map<string, CompanyIndustryFilterValue[number]>();

  raw.forEach((node) => {
    let category: string | undefined = node.category;
    let sector: string | undefined = node.sector;

    if (!category) {
      for (const catOpt of allOptions) {
        const catName = catOpt.value.category;
        if (!catName) continue;
        if (node.sector && Array.isArray(catOpt.options)) {
          const foundSec = (catOpt.options as Option<IndustryNode>[]).find(
            (s) => s.value.sector === node.sector,
          );
          if (foundSec) {
            category = catName;
            break;
          }
        }
        if (node.sub_sector && Array.isArray(catOpt.options)) {
          for (const s of catOpt.options as Option<IndustryNode>[]) {
            if (Array.isArray(s.options)) {
              const foundSub = (s.options as Option<IndustryNode>[]).find(
                (subOpt) => subOpt.value.sub_sector === node.sub_sector,
              );
              if (foundSub) {
                category = catName;
                if (!node.sector) {
                  sector = s.value.sector; // infer sector
                }
                break;
              }
            }
          }
          if (category) break;
        }
      }
    }
    if (!category) return;

    let catEntry = outputMap.get(category);
    if (!catEntry) {
      const catOpt = allOptions.find((opt) => opt.value.category === category);
      catEntry = {
        category,
        sectors: [],
        total_companies: catOpt?.value.total_companies,
      };
      outputMap.set(category, catEntry);
    }
    const finalCatEntry = catEntry;

    if (node.sectors && Array.isArray(node.sectors)) {
      node.sectors.forEach((rawSector) => {
        if (!rawSector.sector) return;
        // Look up the sector in finalCatEntry
        let secEntry = finalCatEntry.sectors?.find((s) => s.sector === rawSector.sector);
        if (!secEntry) {
          secEntry = {
            sector: rawSector.sector,
            sub_sectors: rawSector.sub_sectors
              ? rawSector.sub_sectors.filter(
                  (s): s is { exclude?: boolean; sub_sector: string; total_companies?: number } =>
                    typeof s.sub_sector === 'string',
                )
              : [],
            exclude: rawSector.exclude,
            total_companies: rawSector.total_companies,
          };
          finalCatEntry.sectors?.push(secEntry);
        } else {
          if (rawSector.sub_sectors && Array.isArray(rawSector.sub_sectors)) {
            const existingSubs = (secEntry.sub_sectors || []).filter(
              (s): s is { exclude?: boolean; sub_sector: string; total_companies?: number } =>
                typeof s.sub_sector === 'string',
            );
            const newSubs = rawSector.sub_sectors.filter(
              (s): s is { exclude?: boolean; sub_sector: string; total_companies?: number } =>
                typeof s.sub_sector === 'string',
            );
            secEntry.sub_sectors = unionBy(existingSubs, newSubs, 'sub_sector');
          }
        }
      });
    } else if (node.sector) {
      let secEntry = finalCatEntry.sectors?.find((s) => s.sector === node.sector);
      if (!secEntry) {
        secEntry = {
          sector: node.sector,
          sub_sectors: node.sub_sectors ? [...(node.sub_sectors as any[])] : [],
          total_companies: node.total_companies,
          exclude: node.exclude,
        };
        finalCatEntry.sectors?.push(secEntry);
      } else {
        if (node.sub_sectors && Array.isArray(node.sub_sectors)) {
          const existingSubs = (secEntry.sub_sectors || []).filter(
            (s): s is { exclude?: boolean; sub_sector: string; total_companies?: number } =>
              typeof s.sub_sector === 'string',
          );
          const newSubs = node.sub_sectors.filter(
            (s): s is { exclude?: boolean; sub_sector: string; total_companies?: number } =>
              typeof s.sub_sector === 'string',
          );
          secEntry.sub_sectors = unionBy(existingSubs, newSubs, 'sub_sector');
        }
      }
    } else if (node.sub_sector) {
      const categoryNodes = raw.filter((item) => !!item.category);

      const subSectorInCategoryNode = categoryNodes.some((catNode) => {
        if (catNode.sectors) {
          return catNode.sectors.some((sec) =>
            sec.sub_sectors?.some((sub) => sub.sub_sector === node.sub_sector),
          );
        }
        if (catNode.sub_sectors) {
          return catNode.sub_sectors.some((sub) => sub.sub_sector === node.sub_sector);
        }
        return false;
      });

      if (subSectorInCategoryNode) {
        if (finalCatEntry.sectors) {
          finalCatEntry.sectors = finalCatEntry.sectors.map((secEntry) => {
            if (secEntry.sub_sectors?.some((sub) => sub.sub_sector === node.sub_sector)) {
              return {
                ...secEntry,
                sub_sectors: (secEntry.sub_sectors || []).filter(
                  (sub) => sub.sub_sector !== node.sub_sector,
                ),
              };
            }
            return secEntry;
          });
          if (finalCatEntry.sectors.length === 1) {
            finalCatEntry.sectors = finalCatEntry.sectors.filter(
              (secEntry) => !secEntry.sub_sectors || secEntry.sub_sectors.length > 0,
            );
            if (finalCatEntry.sectors.length === 0) {
              outputMap.delete(category);
            }
          }
        }
      } else {
        for (const catOpt of allOptions) {
          if (catOpt.value.category === category && Array.isArray(catOpt.options)) {
            const secOpt = (catOpt.options as Option<IndustryNode>[]).find(
              (s) =>
                s.options &&
                Array.isArray(s.options) &&
                s.options.find((subOpt) => subOpt.value.sub_sector === node.sub_sector),
            );
            if (secOpt) {
              sector = secOpt.value.sector;
              break;
            }
          }
        }
        if (sector) {
          let secEntry = finalCatEntry.sectors?.find((s) => s.sector === sector);
          if (!secEntry) {
            secEntry = { sector, sub_sectors: [] };
            finalCatEntry.sectors?.push(secEntry);
          }
          if (!secEntry.sub_sectors?.find((s) => s.sub_sector === node.sub_sector)) {
            secEntry.sub_sectors?.push({
              sub_sector: node.sub_sector!,
              exclude: node.exclude,
              total_companies: node.total_companies,
            });
          }
        }
      }
    }
  });

  return Array.from(outputMap.values());
};

export interface NestedSelectFilterProps extends FilterProps {
  autocomplete?: boolean;
  badgeDisplayCap?: number;
  by?: 'category';
  container: React.RefObject<HTMLDivElement>;
  dense?: boolean;
  industries: any[]; // Expecting IndustryWithSubindustries from your unified types
  name: CompanyFilterName.CompanyIndustry;
  nestedBy?: 'sectors';
  placeholder: string;
  showIncludeExclude?: boolean;
  showSelectAll?: boolean;
  totalCompanies: number;
}

const NestedSelectFilter: React.FC<NestedSelectFilterProps> = ({
  industries,
  name,
  placeholder,
  showIncludeExclude = false,
  showSelectAll = false,
  variant,
  dense,
  container,
  autocomplete = false,
  badgeDisplayCap,
  by = 'category',
  nestedBy = 'sectors',
  totalCompanies,
  ...rest
}) => {
  const [query, setQuery] = useState('');
  const tokens = useMemo(() => compact(query.split(/\s*,\s*/)), [query]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { value, onChange, onClear } = useFilter(name);
  const filterValue = useMemo(() => (value as CompanyIndustryFilterValue) || [], [value]);

  const formatNumber = (num: number) => new Intl.NumberFormat().format(num); // Format number with commas
  const allOptions = useMemo(() => {
    return industries.map((cat: any) => {
      const catCompanies =
        cat.total_companies !== undefined ? ` (${formatNumber(cat.total_companies)})` : '';
      return {
        id: `cat-${cat.category}`,
        label: `${cat.category}${catCompanies}`,
        value: { category: cat.category, exclude: false, total_companies: cat.total_companies },
        options: cat.sectors.map((sec: any) => {
          const secCompanies =
            sec.total_companies !== undefined ? ` (${formatNumber(sec.total_companies)})` : '';
          return {
            id: `sec-${cat.category}-${sec.sector}`,
            label: `${sec.sector}${secCompanies}`,
            value: { sector: sec.sector, exclude: false, total_companies: sec.total_companies },
            options: sec.sub_sectors.map((sub: any) => {
              const subCompanies =
                sub.total_companies !== undefined ? ` (${formatNumber(sub.total_companies)})` : '';
              return {
                id: `sub-${cat.category}-${sec.sector}-${sub.sub_sector}`,
                label: `${sub.sub_sector}${subCompanies}`,
                value: {
                  sub_sector: sub.sub_sector,
                  exclude: false,
                  total_companies: sub.total_companies,
                },
              };
            }),
          };
        }),
      };
    });
  }, [industries]);

  const filteredOptions = useMemo(() => filterOptions(allOptions, query), [allOptions, query]);

  const calculateTotalCompanies = (options: Option<any>[]) => {
    return options.reduce((total, option) => {
      const categoryTotal = option.value.total_companies || 0;
      if (!option.options) {
        return total + categoryTotal;
      }
      // TODO
      // const sectorTotal = option.options.reduce((secTotal, sector) => {
      //   const sectorCompanies = sector.value.total_companies || 0;

      //   // Calculate sub-sector total
      //   const subSectorTotal =
      //     sector.options?.reduce((subTotal, subSector) => {
      //       const subSectorCompanies = subSector.value.total_companies || 0;
      //       return subTotal + subSectorCompanies;
      //     }, 0) || 0;

      //   console.log('subSectorTotal', subSectorTotal);
      //   console.log("sectorCompanies", sectorCompanies)
      //   return secTotal + (subSectorTotal || sectorCompanies);
      // }, 0);

      return total + categoryTotal;
    }, 0);
  };

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleChange = useCallback(
    (val: CompanyIndustryFilterValue | undefined) => {
      const rawValue = val as unknown as Array<IndustryNode>;
      const transformed = transformRawValue(rawValue, allOptions);
      onChange(transformed);
    },
    [onChange, allOptions],
  );

  const handleSelectAll = useCallback(() => {
    onChange(uniq([...(filterValue ?? []), ...map(allOptions, 'value')]));
  }, [onChange, allOptions, filterValue]);

  const handleToggle = (option: any) => {
    const newVal = map(filterValue, (v) => {
      if (isEqual(option.value, v)) {
        const val = omit(option.value, ['exclude', nestedBy]);
        return option.value.exclude ? val : { ...val, exclude: true };
      }
      return v;
    });
    onChange(newVal as CompanyIndustryFilterValue);
  };

  const handleClose = () => {
    setQuery('');
  };

  const handleDelete = useCallback(
    (deleted: any) => {
      // Iterate over each category in the current filterValue.
      const newVal: CompanyIndustryFilterValue = filterValue
        .map((cat) => {
          if (cat.category === deleted.category) {
            // If deleted is a category deletion (no sector/sub_sector), remove the entire category.
            if (!deleted.sector && !deleted.sub_sector) {
              return null;
            }
            // If deleting a sector.
            if (deleted.sector) {
              const newSectors = (cat.sectors || []).filter((sec) => sec.sector !== deleted.sector);
              return newSectors.length > 0 ? { ...cat, sectors: newSectors } : null;
            }
            // If deleting a sub_sector.
            if (deleted.sub_sector) {
              const newSectors = (cat.sectors || []).map((sec) => {
                if (sec.sector === deleted.sector) {
                  const newSubs = (sec.sub_sectors || []).filter(
                    (sub) => sub.sub_sector !== deleted.sub_sector,
                  );
                  return { ...sec, sub_sectors: newSubs };
                }
                return sec;
              });
              return { ...cat, sectors: newSectors };
            }
          }
          return cat;
        })
        .filter((cat) => cat !== null) as CompanyIndustryFilterValue;
      onChange(newVal);
    },
    [filterValue, onChange],
  );

  const handleClear = useCallback(() => {
    handleInputClear();
    onClear();
  }, [handleInputClear, onClear]);

  const optionMatchesAToken = (option: any, tokens: string[]): boolean =>
    some(tokens, (token) => option.label.toLowerCase().includes(token.toLowerCase()));

  const handleEnterKey = useCallback(() => {
    if (isEmpty(tokens)) return;
    const newVal = map(allOptions, (option) => {
      const val = pick(option.value, by) as IndustryByCategory;
      if (optionMatchesAToken(option, tokens)) return val;
      const subOpts = filter(option.options, (opt) => optionMatchesAToken(opt, tokens));
      return {
        ...val,
        [nestedBy]: map(subOpts, (opt) => ({ [by]: val[by], ...opt.value })),
      } as IndustryByCategory;
    });
    // (The rest of the merging logic remains as before)
    const isSubset =
      filterValue &&
      every(newVal, (newValItem) => {
        const curr = find(filterValue, { category: newValItem.category }) as IndustryByCategory;
        if (curr) {
          const currNested = map(curr[nestedBy], 'value');
          if (isEmpty(currNested)) return true;
          if (isEmpty(newValItem[nestedBy])) return false;
          return isEmpty(differenceBy(newValItem[nestedBy], currNested, 'sector'));
        }
        return false;
      });
    if (isSubset) {
      const diff = reduce(
        newVal,
        (res, item) => {
          const index = findIndex(res, pick(item, by));
          if (index !== -1) {
            const current = res[index] as IndustryByCategory;
            if (isEmpty(item[nestedBy])) {
              res.splice(index, 1);
            } else {
              const opt = find(allOptions, { value: { category: item.category } });
              const allNested = map(opt?.options, 'value');
              const nestedValues = differenceBy(
                current[nestedBy] || allNested,
                item[nestedBy] || [],
                'sector',
              );
              if (isEmpty(nestedValues)) {
                res.splice(index, 1);
              } else {
                res[index] = { category: item.category, [nestedBy]: nestedValues };
              }
            }
          }
          return res;
        },
        [] as any[],
      );
      onChange(diff);
    } else {
      const merged = reduce(
        newVal,
        (res, item) => {
          const index = findIndex(res, pick(item, by));
          if (index !== -1) {
            const current = res[index] as IndustryByCategory;
            if (isEmpty(current[nestedBy])) return res;
            if (!item[nestedBy]) {
              res[index] = item;
            } else {
              const opt = find(allOptions, { value: { category: item.category } });
              const nestedValues = unionBy(current[nestedBy], item[nestedBy], 'sector');
              if (size(opt?.options) === size(nestedValues)) {
                res[index] = { category: item.category };
              } else {
                res[index] = { category: item.category, [nestedBy]: nestedValues };
              }
            }
            return res;
          }
          return [...res, item];
        },
        [] as any[],
      );
      onChange(merged);
    }
  }, [allOptions, filterValue, tokens, onChange, by, nestedBy]);

  const getBadgeProps = (name: FilterName) => (value: any) => {
    let label = 'n/a';
    if (name === CompanyFilterName.CompanyIndustry) {
      if (value.sic) {
        label = `SIC: ${value.sic}`;
      } else if (value.naics) {
        label = `NAICS: ${value.naics}`;
      } else if (value.sub_sector) {
        label = `${value.sub_sector}${
          value.total_companies ? ` (${formatNumber(value.total_companies)})` : ''
        }`;
      } else if (value.sector) {
        label = `${value.sector}${
          value.total_companies ? ` (${formatNumber(value.total_companies)})` : ''
        }`;
      } else {
        label = `${value.category}${
          value.total_companies ? ` (${formatNumber(value.total_companies)})` : ''
        }`;
      }
    }

    return { key: label, label, exclude: value.exclude };
  };

  const flattenedBadgeValues = filterValue.reduce((res: any[], filter: any) => {
    if (!filter[nestedBy] || filter[nestedBy].length === 0) {
      return [...res, filter];
    }
    const nestedBadges = filter[nestedBy].map((sub: any) => ({
      [by]: filter[by],
      ...sub, // each sub object should have sub_sector, exclude, etc.
    }));
    return [...res, ...nestedBadges];
  }, [] as any[]);

  const Component = variant === 'plain' ? Dropdown : Inline;

  return (
    <Component
      value={flattenedBadgeValues}
      onChange={handleChange}
      multiple
      button={(props) => (
        <FilterInput
          {...props}
          allOptions={allOptions}
          totalCompanies={formatNumber(calculateTotalCompanies(filteredOptions))}
          ref={inputRef}
          variant={variant}
          name={name as FilterName}
          value={reduce(
            filterValue,
            (res, filter) => {
              if (isEmpty(filter[nestedBy])) {
                return [...res, filter];
              } else {
                const fullCategory = allOptions.find(
                  (opt: any) => opt.value.category === filter.category,
                );
                const fullChildren = fullCategory?.options || [];

                if (
                  filter[nestedBy]?.length === fullChildren.length &&
                  filter[nestedBy]?.every((sec: any) => {
                    const fullSector = fullChildren.find(
                      (child: any) => child.value.sector === sec.sector,
                    );
                    return sec.sub_sectors?.length === fullSector?.options?.length;
                  })
                ) {
                  const { [nestedBy]: _, ...pureFilter } = filter;
                  return [...res, pureFilter];
                } else {
                  const nested = map(filter[nestedBy], (v) => ({ [by]: filter[by], ...v }));
                  return [...res, ...nested];
                }
              }
            },
            [] as any[],
          )}
          dense={dense}
          onClear={handleClear}
          onDelete={(item) => handleDelete(item)}
          onInputChange={handleInputChange}
          onInputClear={handleInputClear}
          onEnterKey={handleEnterKey}
          placeholder={placeholder}
          getBadgeProps={getBadgeProps(name)}
          badgeDisplayCap={badgeDisplayCap}
        />
      )}
      container={Component === Dropdown ? container : undefined}
      onClose={Component === Dropdown ? handleClose : undefined}
      offset={[0, variant === 'plain' ? 0 : -12]}
      {...rest}
    >
      {(!autocomplete || !isEmpty(query)) && (
        <>
          {isEmpty(allOptions) && <TextMenuItem>No match for &quot;{query}&quot;</TextMenuItem>}
          {!isEmpty(allOptions) && (
            <>
              {showSelectAll && (
                <LinkMenuItem className="sticky top-0 bg-white" onClick={handleSelectAll}>
                  Select all
                </LinkMenuItem>
              )}
              {map(filteredOptions, (option) => {
                const currentValue = find(filterValue, (v) =>
                  isEqual(pick(v, 'category'), pick(option.value, 'category')),
                );
                const childOptions = option.options || [];
                const expanded = !isEmpty(tokens) && !isEmpty(childOptions);
                return (
                  <IndustryMenuItemItem
                    key={option.id}
                    depth={0} // Top-level category
                    option={{
                      ...option,
                      value: {
                        category: option.value.category,
                        exclude: false,
                        total_companies: option.value.total_companies,
                      },
                      options: option.options?.map((opt: any) => ({
                        ...opt,
                        value: {
                          sector: opt.value.sector,
                          exclude: false,
                          total_companies: opt.value.total_companies,
                        },
                      })),
                    }}
                    options={option.options || []}
                    value={currentValue || null}
                    onChange={(node: IndustryNode | null) => {
                      const currentCategory = option.value.category;
                      const others = filterValue.filter((v) => v.category !== currentCategory);
                      const existing = filterValue.find((v) => v.category === currentCategory);
                      let newCategory: IndustryNode | null = existing
                        ? { ...existing }
                        : {
                            category: currentCategory,
                            total_companies: option.value.total_companies,
                          };

                      if (!node) {
                        newCategory = null;
                      } else if (node.sectors && node.sectors.some((sec) => sec.removed === true)) {
                        const sectorsToRemove = node.sectors
                          .filter((sec) => sec.removed === true && sec.sector)
                          .map((sec) => sec.sector);
                        if (newCategory.sectors) {
                          newCategory.sectors = newCategory.sectors.filter((s) => {
                            return !sectorsToRemove.includes(s.sector);
                          });
                        }
                        if (!newCategory.sectors || newCategory.sectors.length === 0) {
                          newCategory = null;
                        }
                      } else {
                        newCategory = { ...node, category: currentCategory };
                      }

                      const updatedFilter = newCategory ? [...others, newCategory] : others;
                      handleChange(updatedFilter as CompanyIndustryFilterValue);
                    }}
                    onToggle={showIncludeExclude ? handleToggle : undefined}
                    expanded={expanded}
                  />
                );
              })}
            </>
          )}
        </>
      )}
    </Component>
  );
};

export default NestedSelectFilter;
